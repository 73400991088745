:root {
  --mask-src: "";
  --mask-color: "";
  --mask-background: "";
  --mask-rotate: "0";
}

.iconContainer {
  &::after {
    background: var(--mask-color);
    transform: rotate(var(--mask-rotate));
    transition: 0.3s;
    display: inline-block;
    content: "";
    width: 100%;
    height: 100%;
    -webkit-mask-image: var(--mask-src);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 80%;
    -webkit-mask-position: center;
    mask-image: var(--mask-src);
    mask-repeat: no-repeat;
    mask-size: 80%;
    mask-position: center;
  }
  &.circle {
    background: var(--mask-background);
    border-radius: 50%;
    &::before {
      content: "";
      width: 80%;
      height: 80%;
      text-align: center;
      background-position: center;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////

.iconColorContainer{
  &::after {
    display: inline-block;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--mask-src);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  }
}
