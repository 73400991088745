@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  @apply 
    bg-red-400 */
/* bg-purple-500
    bg-emerald-500
    bg-rose-500
    bg-sky-500
    bg-yellow-500
    bg-teal-500
    bg-purple-600
    bg-emerald-600
    bg-rose-600
    bg-sky-600
    bg-yellow-600
    bg-teal-600
    border-purple-700
    border-emerald-700
    border-rose-700
    border-sky-700
    border-yellow-700
    border-teal-700
    ring-purple-700
    ring-emerald-700
    ring-rose-700
    ring-sky-700
    ring-yellow-700
    ring-teal-700
    text-purple-500
    text-emerald-500
    text-rose-500
    text-sky-500
    text-yellow-500
} */

@font-face {
  font-family: "IranSans";
  src: url("./assets/fonts/iransansfanum.ttf") format("truetype");
}

@font-face {
  font-family: "B Traffic";
  src: url("./assets/fonts/btraffic.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  scrollbar-width: 5px;
  box-sizing: border-box;
  font-family: IranSans !important;
  scrollbar-width: thin;
}

body {
  direction: rtl;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* span {
  font-family: IranSans;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@layer utilities {
  .no-spinner {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    /* display: none;  */
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 0px;
  background-color: transparent;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(140, 140, 140);
  border-radius: 2rem;
}

/* HTML: <div class="loader"></div> */
.globalLoading {
  height: 2px;
  width: 100%;
  --c: no-repeat linear-gradient(red 0 0);
  background: var(--c), var(--c), transparent;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}



/* calendar */
.dark .DatePicker {
  /* color: #fff; */
}

.dark .DatePicker .Calendar__day.-selected {
  background-color: #555;
  color: #fff;
}

.dark .DatePicker .Calendar__day.-highlighted {
  background-color: #444;
  color: #fff;
}

.Calendar {
  .Calendar__header {
    .Calendar__monthArrowWrapper {
      .Calendar__monthArrow {
        @apply rotate-180;
        background-image: url("./Components/Common/DatePicker/chevron-downLight.svg") !important;
      }
    }
  }
  /* background-color: #333 !important; */
}

.dark .Calendar {
  @apply bg-gray-700;
  .Calendar__header {
    .Calendar__monthArrowWrapper {
      .Calendar__monthArrow {
        @apply rotate-180 text-gray-200;
        background-image: url("./Components/Common/DatePicker/chevron-downDark.svg") !important;
        color: white !important;
      }
    }
    .Calendar__monthYearContainer {
      .Calendar__monthYear {
        .Calendar__monthText {
          @apply text-gray-100 hover:bg-gray-600;
        }
        .Calendar__yearText {
          @apply text-gray-100 hover:bg-gray-600;
        }
      }
    }
  }
  .Calendar__monthSelectorAnimationWrapper {
    .Calendar__monthSelectorWrapper {
      .Calendar__monthSelector {
        @apply bg-gray-700;
        .Calendar__monthSelectorItem {
          .Calendar__monthSelectorItemText {
            @apply text-gray-100 hover:text-gray-100 hover:bg-gray-500;
          }
        }
        .-active {
          .Calendar__monthSelectorItemText {
            @apply text-gray-100 hover:text-gray-100 bg-sky-500 hover:bg-sky-600;
          }
        }
      }
    }
  }
  .Calendar__yearSelectorAnimationWrapper {
    .Calendar__yearSelectorWrapper {
      @apply after:bg-gradient-to-b from-gray-700 from-0% before:bg-gradient-to-t from-gray-700;
      .Calendar__yearSelector {
        @apply bg-gray-700;
        .Calendar__yearSelectorItem {
          .Calendar__yearSelectorText {
            @apply text-gray-100 hover:text-gray-100 hover:bg-gray-500;
          }
        }
        .-active {
          .Calendar__yearSelectorText {
            @apply text-gray-100 hover:text-gray-100 bg-sky-500 hover:bg-sky-600;
          }
        }
      }
    }
  }
  .Calendar__sectionWrapper {
    .Calendar__section {
      .Calendar__weekRow {
        .Calendar__day {
          @apply text-gray-100 hover:bg-gray-500 hover:text-gray-100;
        }
        .-today {
          @apply after:bg-gray-100;
        }
        .-weekend {
          @apply !text-rose-500 hover:bg-gray-500 hover:text-rose-500;
        }
      }
    }
  }
  /* background-color: #333 !important; */
}

.DatePicker__calendarArrow {
  @apply hidden;
  /* background-color: aqua !important;
  color: aqua !important; */
}



.-selectedStart {
  @apply !bg-sky-500 !text-gray-800
}
.-selectedBetween {
  @apply !bg-sky-500 !bg-opacity-80 !text-gray-800
}
.-selectedEnd {
  @apply !bg-sky-500 !text-gray-800
}