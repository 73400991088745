 /* HTML: <div class="loader"></div> */
 /* .loader {
     width: 15px;
     aspect-ratio: 1;
     border-radius: 50%;
     animation: l5 1s infinite linear alternate;
   }
   @keyframes l5 {
       0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
       33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
       66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
       100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
   } */


  
/* HTML: <div class="loader"></div> */
.loader {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #555555;
    box-shadow: 0 0 0 0 #0004;
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #0004;
    animation: inherit;
    animation-delay: -0.5s;
  }
  .loader:after {
    animation-delay: -1s;
  }
  @keyframes l2 {
      100% {box-shadow: 0 0 50px #0000}
  }
  
  /* HTML: <div class="loader"></div> */
  .dark .loader {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #c0c0c0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.267);
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  .dark .loader:before,
  .dark .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.267);
    animation: inherit;
    animation-delay: -0.5s;
  }
  .dark .loader:after {
    animation-delay: -1s;
  }
  @keyframes l2 {
      100% {box-shadow: 0 0 0 50px #ffffff00} 
  }